import React, { Component } from 'react';
import { Dialog, DialogTitle, Box, Typography, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface SuccessDailogBoxProfileProps {
    open: boolean;
    onClose: () => void;
    goToScreen: (screenName: string) => void;
}

export default class SuccessDailogBoxProfile extends Component<SuccessDailogBoxProfileProps> {
    render() {
        const { open, onClose,goToScreen } = this.props;

        return (
            <StyledDialog
                open={open}
                onClose={onClose}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <img onClick={onClose} style={{ padding: "16px 16px 8px 8px", width: "24px", height: "24px", alignSelf: "end", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={require("../src/cross_icon.png")} />
                <StyledDialogTitle id="success-dialog-title">
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={require("../src/success_icon.png")} alt="Success Icon" style={{ marginBottom: "16px" }} />
                        <Typography className='TextSize'>Your payment has been successfully processed</Typography>
                        <Button variant="text" className='ButtonSize' onClick={() => goToScreen("EmailAccountLoginWeb")}> Log In </Button>
                    </Box>
                </StyledDialogTitle>
            </StyledDialog>
        );
    }
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        background: "#505050",
        maxHeight: "259px",
        maxWidth: "511px",
        padding: "60px",
        "@media (max-width: 1000px)": {
            padding: "40px",
          }, 
          "@media (max-width: 800px)": {
            padding: "20px",
          }, 
          "@media (max-width: 600px)": {
            padding: "15px",
          }, 
    }
});

const StyledDialogTitle = styled(DialogTitle)({
    color: '#ffffff',
    padding: "0px",
    "& .MuiTypography-h6": {
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "28px",
    },
    "& .TextSize": {
        whiteSpace: 'nowrap', 
        color: '#FFF', 
        fontFamily: 'Lato', 
        fontSize: '22px', 
        fontStyle: 'normal', 
        fontWeight: 600,
        "@media (max-width: 1200px)": {
          fontSize: '18px', 
        }, 
        "@media (max-width: 800px)": {
          fontSize: '16px', 
        },
        "@media (max-width: 600px)": {
          fontSize: '12px', 
        },
      },
      "& .ButtonSize": {
        backgroundColor: "#00FF00", 
        marginTop: "30px",
         width: "160px", 
         padding: '10px', 
         textTransform: "none", 
         color: "#242424", 
         fontFamily: "Lato", 
         fontSize: "16px", 
         fontStyle: "normal", 
         fontWeight: 700,
        "@media (max-width: 1200px)": {
          width: "140px", 
          fontSize: "14px", 
        }, 
        "@media (max-width: 800px)": {
          width: "120px", 
          fontSize: "12px", 
        },
      }
});
